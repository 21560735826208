import TypeScript from '../assets/typescript.png'
import MongoDB from '../assets/mongodb.png'
import Redux from '../assets/redux.png'

import Sass from '../assets/sass.png'
import Taiwlind from '../assets/tailwind.png'
import Bootstrap from '../assets/bootstrap.png'

export const primarySkills = [
    {
        "name": "Salesforce",
        "image": 'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fwww.bouncepilot.com%2Fwp-content%2Fuploads%2F2017%2F02%2Flogo-salesforce-white.png&f=1&nofb=1&ipt=ed11d356576a3f49982a35a988a55285f06c11ebcae19c9b43644ffe4cef1dab&ipo=images'
    },
    {
        "name": "React",
        "image": "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fd33wubrfki0l68.cloudfront.net%2Ffbb261dfdedd32e17862eca41366ee2a17b4b4f0%2F7e63a%2Fimg%2Freact-logo-white.png&f=1&nofb=1&ipt=941c4f710524e8d5a8dbc79b227212acb69179cd8fb9922757fed98387a73913&ipo=images"
    },
    {
        "name": "MongoDB",
        "image": MongoDB
    },
    {
        "name": "Redux",
        "image": Redux
    },
    {
        "name": "TypeScript",
        "image": TypeScript
    },
    {
        "name": "Bootstrap",
        "image": Bootstrap
    },
    {
        "name": "Taiwlind",
        "image": Taiwlind
    },
    {
        "name": "SCSS",
        "image": Sass
    },
]