import "./sliderLoop.css";

type SliderProps = {
  skills: {name: String, image: String}[];
};

export const SliderLoop = ({ skills }: SliderProps) => {
  return (
    <>
      <div className="skill-container">
        {skills.map((skill, idx) => {
          return (
            <div className="skill" key={idx}>
              <img className="skill-logo" src={`${skill.image}`} alt="" />
              <span>{skill.name}</span>
            </div>
          );
        })}
      </div>
    </>
  );
};
