import { Link, Outlet } from "react-router-dom";
import Logo from "../../assets/pato.png";
import "./navigation.css";
import { MouseEvent, useEffect, useState } from "react";

type MenuProps = {
  closeMenu: (event: MouseEvent<HTMLElement>) => void;
};

export const Navigation = () => {
  const [menu, setMenu] = useState(false);

  const toggleMenu = (e: MouseEvent<HTMLElement>) => {
    setMenu(!menu);
  };

  useEffect(() => {
    let lastScroll: number = 0;

    window.onscroll = (e) => {
      const scrollTop: number =
        window.scrollY || document.documentElement.scrollTop;

      if (scrollTop > lastScroll) {
      } else {
      }

      lastScroll = scrollTop;
    };
  }, []);

  useEffect(() => {
    const circle: HTMLElement | null = document.querySelector(".circle_menu");

    window.onmousemove = (e) => {
      const x: number = e.clientX;
      const y: number = e.clientY;

      if (circle) {
        circle.style.top = `${y}px`;
        circle.style.left = `${x}px`;
      }
    };
  }, []);

  return (
    <>
      <nav>
        <ul className="navigation">
          <li className="logo">
            <Link to="/">
              <img src={Logo} alt="pato" />
            </Link>
          </li>

          <li className="navigation__menu">
            <button
              onClick={toggleMenu}
              className={`toggle ${menu ? "active" : ""}`}
            ></button>
          </li>
        </ul>
      </nav>

      <section className={`menu ${menu ? "active" : ""}`}>
        <Menu closeMenu={toggleMenu} />
        <div className={`circle_menu ${menu ? "active" : ""}`}></div>
      </section>

      <Outlet />
    </>
  );
};

const Menu = ({ closeMenu }: MenuProps) => {
  return (
    <>
      <div className="menu_links">
        <ul>
          <li>
            <Link onClick={closeMenu} to="/">
              Home
            </Link>
          </li>
          <li>
            <Link onClick={closeMenu} data-index="1" to="/cards">
              Animations
            </Link>
          </li>
          <li>
            <Link onClick={closeMenu} to="/work">
              Work
            </Link>
          </li>
        </ul>
      </div>
      <div className="menu_animations">
        <h1>Are you ready?</h1>
        <h1>we create something</h1>
        <a className="button" href="mailto:jeancarlosayala57@gmail.com">
          Contact me
        </a>

        <a
          className="cv-link"
          href="https://drive.google.com/file/d/1xXzQ--ry94pF_S8R_Xkxl_tAKanciDY-/view?usp=sharing"
          target="_blank"
          rel="noreferrer"
        >
          Pss you want to know more about me? click me!
        </a>
      </div>
    </>
  );
};
