import { proyects } from "../../api/proyects";

import "./work.css";

export const Work = () => {
  return (
    <>
      <article className="work">
        {proyects.map((item, idx) => {
          return (
            <>
              <div className="work_card" key={idx}>
                <h1>{item.name}</h1>
                <div className="urls">
                  <a href={item.git} target="_blank" rel="noreferrer">
                    <i className="fa-brands fa-github"></i>
                  </a>
                  {item.url && (
                    <a href={item.url} target="_blank" rel="noreferrer">
                      <i className="fa-solid fa-link"></i>
                    </a>
                  )}
                </div>
                <span>{item.description}</span>
              </div>
            </>
          );
        })}
      </article>
    </>
  );
};
