import { useEffect } from "react";
import "./loading.css";

export const Loading = () => {

  useEffect(() => {
    const textContainer: HTMLElement | null = document.querySelector(
      ".loading-container__name"
    );
    const text: String = "JCAS";
    const sliceText: string[] = text.split("");

    for (let i: number = 0; i < sliceText.length; i++) {
      const span: HTMLElement = document.createElement("span");
      span.innerText = sliceText[i];
      span.classList.add("loading-container__name__text");

      textContainer?.append(span);
    }

    const nameText: NodeListOf<Element> = document.querySelectorAll(
      ".loading-container__name__text"
    );

    nameText.forEach((item, idx) => {
      (item as HTMLElement).style.animationDelay = `${idx * 0.08}s`;
    });
  }, []);

  return (
    <>
      <div className="loading">
        <main className="loading-container">
          <section className="loading-container__name"></section>
        </main>

        <section className="position">
          <h1>Front End Developer</h1>
        </section>
      </div>
    </>
  );
};
