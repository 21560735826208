import { Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { Navigation } from "./components/Navigation/navigation";
import { Home } from "./pages/Home/home";
import { Cards } from "./pages/Cards/Cards";
import { Work } from "./pages/Work/work";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Navigation />}>
        <Route index element={<Home />} />
        <Route path="cards" element={<Cards />} />
        <Route path="work" element={<Work />} />
      </Route>
    </Routes>
  );
}

export default App;
