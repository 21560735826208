import { Loading } from "../../components/Loading/loading";
import { SliderLoop } from "../../components/SliderLoop/SliderLoop";
import { primarySkills } from "../../api/tecnology";

import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import "./home.css";

export const Home = () => {
  useEffect(() => {
    const timelime = gsap.timeline();
    gsap.registerPlugin(ScrollTrigger);

    timelime.to(".about_first", {
      opacity: "1",
      scrollTrigger: {
        trigger: ".firstView",
        start: "70%",
        end: "bottom bottom",
        scrub: 2,
      },
    });

    timelime.to(".about_title", {
      opacity: "1",
      scrollTrigger: {
        trigger: ".firstView",
        start: "20%",
        end: "50%",
        scrub: 2,
      },
    });

    timelime.to(".about_second", {
      opacity: "1",
      scrollTrigger: {
        trigger: ".about_first",
        start: "50%",
        end: "bottom bottom",
        scrub: 2,
      },
    });
  }, []);

  return (
    <>
      <Loading />

      <main className="firstView">
        <div className="me-container">
          <div className="me">
            <span className="me__name">Hi I'm Jean Carlos</span>
            <span className="me__profesion">
              <span className="fancy">Front End</span> Developer
            </span>
          </div>
        </div>
      </main>

      <article className="slider-container">
        <section className="titles about_title">
          <h1>Experience</h1>
          <span>My experience in competitons in School</span>
        </section>

        <section className="about">
          <div className="about_first">
            <img
              className="about_image"
              src="https://www.gob.mx/cms/uploads/article/main_image/89656/ganador-1.jpg"
              alt=""
            />
            <span>
              We won second place in the first hackaton edtion in Conalep, we
              had to create an application with a solution according to the
              proyect Mayan train, we used differents tecnologies as DataBase,
              AWS and React
              <h1 className="year">2019</h1>
            </span>
          </div>

          <div className="about_second">
            <span>
              We won first place in the second hackaton edtion in Conalep, we
              had to create an application with a solution about the covid, how
              students can take his clases when their go to the school and
              prevent more diseases among them, we used tecnologies as ChatBot
              IA from IBM, React, MongoDb and React
              <h1 className="year">2020</h1>
            </span>
            <img
              className="about_image"
              src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.gob.mx%2Fcms%2Fuploads%2Fimage%2Ffile%2F585471%2FWhatsApp_Image_2020-06-05_at_5.38.31_PM.jpeg&f=1&nofb=1&ipt=18fdd0970a3a6c456d7339080181527efbb6a2ad92131bb9f87f0e3818c74e2c&ipo=images"
              alt=""
            />
          </div>
        </section>

        <section className="titles">
          <h1>Tecnologies and Frameworks</h1>
          <span>
            I have worked with different tools in my{" "}
            <span className="fancy">work</span> and my{" "}
            <span className="fancy">learning</span> process.
          </span>
        </section>

        <section className="skills">
          <SliderLoop skills={primarySkills} />
          <SliderLoop skills={primarySkills} />
        </section>
      </article>

      <footer className="home">
        <div className="contact-me">
          <h1>Are You Ready?</h1>
          <h2>LET'S BUILD SOMETHING</h2>

          <a className="email" href="mailto:jeancarlosayala57@gmail.com">jeancarlosayala57@gmail.com</a>
        </div>
      </footer>
    </>
  );
};
