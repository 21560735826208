export const proyects = [
  {
    "id": 1,
    "name": "BankIo",
    "description": "In this application you can create an account and do a transaction whit your friends and even use the same aplication in my others proyects such as delivery app",
    "git": "https://github.com/Jeancarlosayala/BankIo",
    "url": "https://bank-io.vercel.app/",
    "type": "desk",
  },
  {
    "id": 2,
    "name": "Delivery App",
    "description": "You can buy a differents kind of food using the app, and even you can pay using the BankIo account whit any problem",
    "git": "https://github.com/Jeancarlosayala/commerce-app",
    "url": "https://expo.dev/@jeancarlos28/commerce",
    "type": "mobile",
  },
  {
    "id": 3,
    "name": "Salesforce Marketing Integration",
    "description": "You can use my cartridge if you want to integrate marketing cloud, I use the marketing apis to send the information that you need as newsletter, news account and others",
    "git": "https://github.com/Jeancarlosayala/int_marketing_api",
    "url": null,
    "type": "cartridge",
  }
]