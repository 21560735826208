import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect } from "react";

import "./cards.css";

export const Cards = () => {
  useEffect(() => {
    const timeline = gsap.timeline();
    gsap.registerPlugin(ScrollTrigger);

    timeline.to(".fourth", {
      transform: "translateY(-180%) rotate(-35deg)",
      scrollTrigger: {
        trigger: ".view-cards",
        start: "top top",
        end: "8%",
        scrub: 1,
      },
    });

    timeline.to(".third", {
      transform: "translateY(-180%) rotate(-35deg)",
      scrollTrigger: {
        trigger: ".view-cards",
        start: "25%",
        end: "33%",
        scrub: 1,
      },
    });

    timeline.to(".second", {
      transform: "translateY(-180%) rotate(-35deg)",
      scrollTrigger: {
        trigger: ".view-cards",
        start: "50%",
        end: "58%",
        scrub: 1,
      },
    });

    timeline.to(".first", {
      transform: "translateY(-180%) rotate(-35deg)",
      scrollTrigger: {
        trigger: ".view-cards",
        start: "75%",
        end: "83%",
        scrub: 1,
      },
    });

    timeline.to(".card-scroll__text", {
      top: "3240px",
      position: "relative",
      scrollTrigger: {
        trigger: ".view-cards",
        start: "90%",
        end: "bottom bottom",
        scrub: true,
      },
    });
    
  }, []);

  return (
    <>
      <main className="view-cards">
        <article className="card-scroll-container">
          <h1 className="card-scroll__text">Cards Animations</h1>

          <div className="card-scroll first"></div>
          <div className="card-scroll second"></div>
          <div className="card-scroll third"></div>
          <div className="card-scroll fourth"></div>
        </article>
      </main>
    </>
  );
};
